<template>
  <div>
    <a-card :bordered="false">
      <div>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-card title="客户销量（TOP20）" :bordered="false">
              <a-row :gutter="24">
                <a-col :span="8">
                  <a-form-item label="时间">
                    <a-range-picker :defaultValue="[statisticCustomerQueryParam.start_date, statisticCustomerQueryParam.end_date]" @change="onDateChangeCustomer"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="品类">
                    <a-select
                      v-model="statisticCustomerQueryParam.good_type_id"
                      placeholder="请选择品类"
                      style="width: 100%"
                      @change="loadCustomerTrend"
                      allowClear
                    >
                      <a-select-option v-for="op in goodTypeIds" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="类别">
                    <a-select
                      v-model="statisticCustomerQueryParam.amount_type"
                      placeholder="请选择类别"
                      style="width: 100%"
                      @change="loadCustomerTrend"
                    >
                      <a-select-option v-for="op in typeData" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="4">
                  <a-form-item label="图形">
                    <a-select
                      v-model="statisticCustomerQueryParam.echart_type"
                      placeholder="请选择类别"
                      style="width: 100%"
                      @change="loadCustomerTrend"
                    >
                      <a-select-option v-for="op in echartTypeData" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <div ref="customerStatisticChart" style="width: 100%; height: 520px;"></div>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-card title="商品销量（TOP20）" :bordered="false">
              <a-row :gutter="24">
                <a-col :span="8">
                  <a-form-item label="时间">
                    <a-range-picker :defaultValue="[statisticGoodsQueryParam.start_date, statisticGoodsQueryParam.end_date]" @change="onDateChangeGoods"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="品类">
                    <a-select
                      v-model="statisticGoodsQueryParam.good_type_id"
                      placeholder="请选择品类"
                      style="width: 100%"
                      @change="loadGoodsTrend"
                      allowClear
                    >
                      <a-select-option v-for="op in goodTypeIds" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="类别">
                    <a-select
                      v-model="statisticGoodsQueryParam.amount_type"
                      placeholder="请选择类别"
                      style="width: 100%"
                      @change="loadGoodsTrend"
                    >
                      <a-select-option v-for="op in typeData" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="4">
                  <a-form-item label="图形">
                    <a-select
                      v-model="statisticGoodsQueryParam.echart_type"
                      placeholder="请选择类别"
                      style="width: 100%"
                      @change="loadGoodsTrend"
                    >
                      <a-select-option v-for="op in echartTypeData" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <div ref="goodsStatisticChart" style="width: 100%; height: 520px;"></div>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-card title="每月销量趋势" :bordered="false">
              <a-row :gutter="24">
                <a-col :span="8">
                  <a-form-item label="时间">
                    <a-range-picker :defaultValue="[statisticMonthsQueryParam.start_date, statisticGoodsQueryParam.end_date]" @change="onDateChangeMonths"/>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="品类">
                    <a-select
                      v-model="statisticMonthsQueryParam.good_type_id"
                      placeholder="请选择品类"
                      style="width: 100%"
                      @change="loadMonthsTrend"
                      allowClear
                    >
                      <a-select-option v-for="op in goodTypeIds" :key="op.value">
                        {{ op.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
              </a-row>
              <div ref="monthsStatisticChart" style="width: 100%; height: 520px;"></div>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>

<script>
import {
  getGoodType,
  statisticByCustomer,
  statisticBygoods,
  statisticMonths
} from '@/api/wms/sale'
// import store from '@/store'
import moment from 'moment'

export default {
  name: 'WareHouseControl',
  data() {
    return {
      statisticCustomerQueryParam: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        amount_type: 'nonetax',
        echart_type: 'bar',
        good_type_id: null
      },
      statisticGoodsQueryParam: {
        start_date: moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        amount_type: 'nonetax',
        echart_type: 'bar',
        good_type_id: null
      },
      statisticMonthsQueryParam: {
        start_date: moment(new Date()).subtract(12, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
        good_type_id: null
      },
      typeData: [
        { value: 'nonetax', name: '去税销量' },
        { value: 'tax', name: '含税销量' },
        { value: 'profit', name: '利润' },
        { value: 'num', name: '数量' }
      ],
      echartTypeData: [
        { value: 'bar', name: '柱状图' },
        { value: 'pie', name: '饼图' }
      ],
      goodTypeIds: [],
      companyExternalOps: [],
      labelOption: {
        show: true,
        position: 'insideBottom',
        distance: 15,
        align: 'left',
        verticalAlign: 'middle',
        rotate: 90,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      },
      customerPieChartOption: {},
      goodsPieChartOption: {},
      monthsPieChartOption: {}
    }
  },
  mounted() {
    this.init()
    this.loadCustomerTrend()
    this.loadGoodsTrend()
    this.loadMonthsTrend()
  },
  methods: {
    init() {
      getGoodType().then(res => {
        res.forEach(element => {
          this.goodTypeIds.push({ value: element.id, name: element.good_type })
        });
      })
    },
    onDateChangeCustomer(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.statisticCustomerQueryParam['start_date'] = startDate
        this.statisticCustomerQueryParam['end_date'] = endDate
      } else {
        this.statisticCustomerQueryParam['start_date'] = null
        this.statisticCustomerQueryParam['end_date'] = null
      }
      this.loadCustomerTrend()
    },
    // 加载订单统计图数据源
    loadCustomerTrend() {
      statisticByCustomer(this.statisticCustomerQueryParam).then(res => {
        let totalNum = 0
        if (this.statisticCustomerQueryParam.echart_type === 'pie') {
          const dataSource = []
          res.forEach(item => {
            dataSource.push({ value: parseFloat(item.yamount.toFixed(2)), name: item.xname })
          })
          this.customerPieChartOption = {
            title: {
                text: '', // 主标题 (主标题里面来动态显示当前数据总数)
                subtext: '总数', // 副标题
                x: 'center', // 标题x轴居中（也可以是: left || center || right || 百分比：xx% || xx(默认单位px)  ）
                y: 'center', // 标题y轴居中（也可以是: top || center || bottom || 百分比：xx% || xx(默认单位px)  ）
                itemGap: -5, // 主标题和副标题之间的距离(默认为 0)
                textStyle: { // 设置主标题样式
                    fontSize: 30,
                    color: '#666',
                    backroung: 'blue'
                },
                subtextStyle: { // 设置副标题样式
                    fontSize: 20,
                    color: '#666'
                }
            },
            tooltip: { trigger: 'item' },
            legend: { orient: 'vertical', left: 'left' },
            toolbox: { feature: { saveAsImage: {} } },
            series: [
              {
                type: 'pie',
                radius: ['30%', '60%'],
                label: { formatter: `{b}: {c} ({d}%)` },
                data: dataSource,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
          dataSource.forEach(v => {
              totalNum += v.value
          })
        } else {
          const xData = []
          const yData = []
          res.forEach(item => {
            xData.push(item.xname)
            yData.push(parseFloat(item.yamount.toFixed(2)))
            totalNum += parseFloat(item.yamount.toFixed(2))
          })
          this.customerPieChartOption = {
            title: {
                text: '', // 主标题 (主标题里面来动态显示当前数据总数)
                subtext: '总数', // 副标题
                x: 'center', // 标题x轴居中（也可以是: left || center || right || 百分比：xx% || xx(默认单位px)  ）
                y: 'center', // 标题y轴居中（也可以是: top || center || bottom || 百分比：xx% || xx(默认单位px)  ）
                itemGap: -5, // 主标题和副标题之间的距离(默认为 0)
                textStyle: { // 设置主标题样式
                    fontSize: 30,
                    color: '#666',
                    backroung: 'blue'
                },
                subtextStyle: { // 设置副标题样式
                    fontSize: 20,
                    color: '#666'
                }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: xData,
              axisLabel: { interval: 0, rotate: -20 }
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: yData,
                type: 'bar',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                }
              }
            ]
          }
        }
        this.customerPieChartOption.title.text = parseFloat(totalNum.toFixed(2))
        this.drawCustomerTrendChart()
      })
    },
    // 绘画订单统计图
    drawCustomerTrendChart() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.customerStatisticChart)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.customerStatisticChart)
        }
        let echartsArr = []; // 点击图例后所剩的数据名
        let echartsNum = 0;// 点击图例后所剩的数据的总和
        // 利用echarts自带的legendselectchanged方法获取点击后的数据(但是这里只能获取到名字)
        myChart.on('legendselectchanged', (params) => {
            echartsArr = [];// 将点击后的数组设为空（每点击一次就重新判断添加）
            // 循环点击图例后获取到的名字
            for (let key in params.selected) {
                // 判断值是否为true 将值为true的名字push到echartsArr数组当中保留起来
                if (params.selected[key]) {
                    echartsArr.push(key)
                }
            }
            echartsNum = 0; // 将总数的值设为0（每点击一次就重新计算）
            // 循环判断数据的全部数据里的name值是否与我们点击图例后所剩数据的数组相等
            // 相等的话就将其value值进行相加得出点击图例后所剩数据的总数
            this.customerPieChartOption.series[0].data.forEach(item => {
                echartsArr.forEach(v => {
                    if (item.name === v) {
                        echartsNum += item.value
                    }
                })
            })
            this.customerPieChartOption.title.text = parseFloat(echartsNum.toFixed(2)) // 最后将其赋值给主标题即可
            this.$nextTick(() => {
                myChart.setOption(this.customerPieChartOption);
            })
        })
        myChart.setOption(this.customerPieChartOption)
      }, 500)
    },
    onDateChangeGoods(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.statisticGoodsQueryParam['start_date'] = startDate
        this.statisticGoodsQueryParam['end_date'] = endDate
      } else {
        this.statisticGoodsQueryParam['start_date'] = null
        this.statisticGoodsQueryParam['end_date'] = null
      }
      this.loadGoodsTrend()
    },
    loadGoodsTrend() {
      statisticBygoods(this.statisticGoodsQueryParam).then(res => {
        let totalNum = 0
        if (this.statisticGoodsQueryParam.echart_type === 'pie') {
          const dataSource = []
          res.forEach(item => {
            dataSource.push({ value: parseFloat(item.yamount.toFixed(2)), name: item.xname })
          })
          this.goodsPieChartOption = {
            title: {
                text: '', // 主标题 (主标题里面来动态显示当前数据总数)
                subtext: '总数', // 副标题
                x: 'center', // 标题x轴居中（也可以是: left || center || right || 百分比：xx% || xx(默认单位px)  ）
                y: 'center', // 标题y轴居中（也可以是: top || center || bottom || 百分比：xx% || xx(默认单位px)  ）
                itemGap: -5, // 主标题和副标题之间的距离(默认为 0)
                textStyle: { // 设置主标题样式
                    fontSize: 30,
                    color: '#666',
                    backroung: 'blue'
                },
                subtextStyle: { // 设置副标题样式
                    fontSize: 20,
                    color: '#666'
                }
            },
            tooltip: { trigger: 'item' },
            legend: { orient: 'vertical', left: 'left' },
            toolbox: { feature: { saveAsImage: {} } },
            series: [
              {
                type: 'pie',
                radius: ['30%', '60%'],
                label: { formatter: `{b}: {c} ({d}%)` },
                data: dataSource,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          }
          dataSource.forEach(v => {
              totalNum += v.value
          })
        } else {
          const xData = []
          const yData = []
          res.forEach(item => {
            xData.push(item.xname)
            yData.push(parseFloat(item.yamount.toFixed(2)))
            totalNum += parseFloat(item.yamount.toFixed(2))
          })
          this.goodsPieChartOption = {
            title: {
                text: '', // 主标题 (主标题里面来动态显示当前数据总数)
                subtext: '总数', // 副标题
                x: 'center', // 标题x轴居中（也可以是: left || center || right || 百分比：xx% || xx(默认单位px)  ）
                y: 'center', // 标题y轴居中（也可以是: top || center || bottom || 百分比：xx% || xx(默认单位px)  ）
                itemGap: -5, // 主标题和副标题之间的距离(默认为 0)
                textStyle: { // 设置主标题样式
                    fontSize: 30,
                    color: '#666',
                    backroung: 'blue'
                },
                subtextStyle: { // 设置副标题样式
                    fontSize: 20,
                    color: '#666'
                }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              data: xData,
              axisLabel: { interval: 0, rotate: -20 }
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                data: yData,
                type: 'bar',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                }
              }
            ]
          }
        }
        this.goodsPieChartOption.title.text = parseFloat(totalNum.toFixed(2))
        this.drawGoodsTrendChart()
      })
    },
    drawGoodsTrendChart() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.goodsStatisticChart)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.goodsStatisticChart)
        }
        let echartsArr = []; // 点击图例后所剩的数据名
        let echartsNum = 0;// 点击图例后所剩的数据的总和
        // 利用echarts自带的legendselectchanged方法获取点击后的数据(但是这里只能获取到名字)
        myChart.on('legendselectchanged', (params) => {
            echartsArr = [];// 将点击后的数组设为空（每点击一次就重新判断添加）
            // 循环点击图例后获取到的名字
            for (let key in params.selected) {
                // 判断值是否为true 将值为true的名字push到echartsArr数组当中保留起来
                if (params.selected[key]) {
                    echartsArr.push(key)
                }
            }
            echartsNum = 0; // 将总数的值设为0（每点击一次就重新计算）
            // 循环判断数据的全部数据里的name值是否与我们点击图例后所剩数据的数组相等
            // 相等的话就将其value值进行相加得出点击图例后所剩数据的总数
            this.goodsPieChartOption.series[0].data.forEach(item => {
                echartsArr.forEach(v => {
                    if (item.name === v) {
                        echartsNum += item.value
                    }
                })
            })
            this.goodsPieChartOption.title.text = parseFloat(echartsNum.toFixed(2)) // 最后将其赋值给主标题即可
            this.$nextTick(() => {
                myChart.setOption(this.goodsPieChartOption);
            })
        })
        myChart.setOption(this.goodsPieChartOption)
      }, 500)
    },
    onDateChangeMonths(date, dateStr) {
      if (dateStr[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.statisticMonthsQueryParam['start_date'] = startDate
        this.statisticMonthsQueryParam['end_date'] = endDate
      } else {
        this.statisticMonthsQueryParam['start_date'] = null
        this.statisticMonthsQueryParam['end_date'] = null
      }
      this.loadMonthsTrend()
    },
    loadMonthsTrend() {
      statisticMonths(this.statisticMonthsQueryParam).then(res => {
        const xAxisData = []
        const totalNumSeriesData = []
        const totalPriceInSeriesData = []
        const totalPriceInTaxSeriesData = []
        const totalPriceOutSeriesData = []
        const totalPriceOutTaxOutSeriesData = []
        const totalProfitSeriesData = []
        res.forEach(item => {
          xAxisData.push(item.sell_date)
          totalNumSeriesData.push(item.total_num)
          totalPriceInSeriesData.push(item.total_price_in)
          totalPriceInTaxSeriesData.push(item.total_price_in_tax)
          totalPriceOutSeriesData.push(item.total_price_out)
          totalPriceOutTaxOutSeriesData.push(item.total_price_out_tax)
          totalProfitSeriesData.push(item.total_profit)
        })
          this.monthsPieChartOption = {
            title: {
              text: '月度销量统计'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['数量', '采购金额', '采购金额(去税)', '销售金额', '销售金额(去税)', '利润(去税)']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xAxisData
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: '数量',
                type: 'line',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                },
                data: totalNumSeriesData
              },
              {
                name: '采购金额',
                type: 'line',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                },
                data: totalPriceInSeriesData
              },
              {
                name: '采购金额(去税)',
                type: 'line',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                },
                data: totalPriceInTaxSeriesData
              },
              {
                name: '销售金额',
                type: 'line',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                },
                data: totalPriceOutSeriesData
              },
              {
                name: '销售金额(去税)',
                type: 'line',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                },
                data: totalPriceOutTaxOutSeriesData
              },
              {
                name: '利润(去税)',
                type: 'line',
                label: {
                  show: true, // 显示数值
                  position: 'top' // 在顶部显示
                },
                data: totalProfitSeriesData
              }
            ]
          }
        this.drawMonthsTrendChart()
      })
    },
    drawMonthsTrendChart() {
      setTimeout(() => {
        let myChart = this.$echarts.getInstanceByDom(this.$refs.monthsStatisticChart)
        if (myChart == null) {
          myChart = this.$echarts.init(this.$refs.monthsStatisticChart)
        }
        myChart.setOption(this.monthsPieChartOption)
      }, 500)
    }
  }
}
</script>

<style scoped>

</style>
